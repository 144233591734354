
.backdrop{
    height: 50px;
    width: 50px;
    z-index: 700;
    border: none;
    padding: 20px;
    border-radius: 200%;
    cursor: pointer;
    background: #31313100;
    flex-direction: column;
    display: flex;
    position: fixed;
    right: 35px;
    top: 10px;
    box-sizing: border-box;
}
.backdrop:hover{
    background-color: rgb(73, 73, 73);
}
.moveX{
    margin-top: 2px;
    margin-left: -12px;
}
.click{
    z-index: 600;
}
.toggle_line_x1{
    width: 30px;
    height: 1px;
    margin-bottom: 4px;
    padding: 2px;
    border-radius: 10px;
    transform: rotate(45deg); 
    background:   rgb(55, 177, 161);
}

.toggle_line_x1:hover{
    background-color: white;
   }
.toggle_line_x2:hover{
 background-color: white;
}
.toggle_line_x2{
    width: 30px;
    height: 1px;
    padding: 2px;
    margin-top: -8.5px;
    border-radius: 10px;
    transform: rotate(-45deg); 
    background:   rgb(55, 177, 161);
}
@media only screen and (max-width: 600px) {
    .backdrop{
        top: 12px;
        right: 16px;
    }

}