@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
.albums-portraits-background{
    height: 350px;
    width: 80%;
    margin: auto;
    margin-top: 0px;
    background-position-y: -100px;
}
.first-link-wrap{
    padding-top: 80px;
    height: auto;
    background-color: rgb(27, 27, 27);
}
.albums-family-background{
    height: 350px;
    width: 80%;
    margin: auto;
    margin-top: 0px;
    border-radius: 10px;
    background-position-y: -300px;
}
.portraits_link{
   text-decoration: none;

}
#album-page{
    height: auto !important;
}
.portraits-link-text{
    width: 100%;
   bottom: 0px;
   height: 100%;
   overflow:  hidden !important;
   color: rgba(255, 255, 255, 0.692);
    font-size: 10rem;
    font-family: 'Caveat', cursive;
    font-weight: 100;
    margin-top: 10px !important;
    border-radius: 10px 10px 10px 10px !important;
}
.portraits-link-text:hover{
  color: rgba(99, 99, 99, 0.61);
}
.link-text-p{
    margin: 20px;
}
.albums-link-wrap{
   overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .albums-portraits-background{
        height: 400px;
        width: 100% !important;
        border-radius: 10px;
        background-position-y: 0px;
    }
    .albums-family-background{
        height: 400px;
        border-radius: 10px;
        width: 100% !important;
        background-position-y: 0px;
        background-position-x: 0px;
    }
    .portraits-link-text{
    font-size: 6rem;
    height: 75%;
    padding-top: 0px;
    color: rgba(221, 221, 221, 0.795);
    overflow-y: hidden !important;
    border-radius: 10px 10px 0px 0px !important;
    }
}
@media only screen and (max-width: 420px) {
 
    .albums-family-background{
        height: 400px;
        border-radius: 10px;
        width: 100% !important;
        background-position-y: 0px;
        background-position-x: -80px;
    }

}
