@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
html{
    width: 100%;

    overflow-y: scroll !important;
}
body{
    width: 100% !important;
    overflow-x: hidden;
    overflow-y: scroll !important;
    height: auto !important;
}
.smooth{
    height: 100vh !important;
}

.Pages{
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.about-wrap{
    padding-top: 100px;
    width: 100%;
    overflow-y: scroll;
}
.about-topgroup-wrap{
     display: flex;
    padding-top: 50px;
      margin: auto;
     width: 800px;
}
.awssld__wrapper{
    overflow-y: scroll;
}
.awssld__bullets button{
    background-color: rgb(223, 223, 223) !important;
}
.awssld__bullets .awssld__bullets--active{
    background-color:  rgba(55, 177, 161, 0.603) !important;
}
.about-photo{
    padding-top: 100px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 40px;
    box-shadow: 8px 5px 6px rgba(0, 0, 0, 0.178);
    width: 300px !important;
}
#aboutTitle{
    text-align: right;
}

.awssld__content{
    overflow-x: hidden;
    width: 100% !important;
    background-color: rgb(245, 245, 245) !important;
  
}
.slideImages{
    height: auto !important;
    width: 100% !important;
   
}
.slide-show-wrap{
    padding-bottom: 100px;
}
.about-title1{
    color: silver;
    padding-right: 18px;
    font-size: 2rem;
    font-weight: 100;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    margin-bottom: -8px;
    letter-spacing: 7px;
    }
    .about-title2{
    color: gray;
    font-family: 'Roboto';
    padding-right: 20px;
    font-size: 2.5rem;
    margin-top: 0px;
    letter-spacing: 7px;
    }
    .slide-link{
        font-size: 1.3rem;
        text-decoration: none;
       width: 250px;
       padding: 100px;
       height: 150px;
       border-radius: 200px;
       background-color: rgb(230, 230, 230);
        font-family: sans-serif;
        color: rgb(139, 139, 139);
    }
.about-title1{
color: silver;
padding-right: 18px;
font-size: 2rem;
font-weight: 100;
font-style: italic;
font-family: 'Roboto', sans-serif;
margin-bottom: -8px;
letter-spacing: 7px;
}
.about-title2{
color: gray;
font-family: 'Roboto';
padding-right: 20px;
font-size: 2.5rem;
margin-top: 0px;
letter-spacing: 7px;
}
.about-text-wrap-1{
    width: 100%;
    font-size: 1.6rem;
    padding: 30px;
    overflow-y: scroll !important;
    margin: auto;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(126, 126, 126);
}
.about-text-wrap-2{
    width: 800px;
    margin: auto;
    margin-top: -50px;
    font-size: 1.6rem;
    overflow-y: scroll !important;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(126, 126, 126);
    padding: 30px;
    padding-bottom: 100px;
  
}
.sc-bwzfXH {
 width: 100%
 
}
.fhNIzM{
   height: auto !important;
    width: 400px;
}
@media only screen and (max-width: 1400px) {

  }
  @media only screen and (max-width: 900px) {
    .about-topgroup-wrap{
        display: flex;
        padding-top: 50px;
          margin: auto;
          flex-direction: column;
         width: 90%;
         
    }
    .about-wrap{
        padding-top: 100px;
        width: 100%;
    }
    .about-text-wrap-1{
        width: 85%;
        text-align: left;
        display: flex;
        height: auto;
        font-size: 1.4rem;
        padding: 10px;
    }
  
    .about-text-wrap-2{
        width: 80%;
        margin: auto;
        padding-bottom: 60px;
        text-align: left;
 
        font-size: 1.4rem;

    }
    .sc-bwzfXH {
        width: auto;
        min-width: none;
        max-height: 700px;
       }
       .about-photo{
        width: 200px !important;
        padding: 10px;
        display: flex;
        margin: auto;
        width: 100%;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.411) !important;
        min-width: none !important;
    }
       .fhNIzM{
           width: auto;
           display: flex;
           flex-wrap: wrap;
           
       }

  }
  @media only screen and (max-width: 600px) {
    #aboutTitle{
        text-align: center;
        padding-left: 20px;
    }
    .about-photo{
        width: 85% !important;
        padding: 10px;

        min-width: none !important;
    }
    .slideImages{
        height: auto;
        width: 100%;
        padding: 0px;
        
        box-shadow: none;
    }
  }
  @media only screen and (max-width: 400px) {
    .about-text-wrap-1{
      
        font-size: 1.3rem;
    }
    .about-text-wrap-2{
        margin-top: -40px;
        font-size: 1.3rem;
    }
  
    .about-topgroup-wrap{
        flex-wrap: wrap;
    }
}