.header{
    background-color: rgba(204, 204, 204, 0.13);
    width: 100% ;
    height: 80px !important;
    z-index: 2;
    position: fixed;
    top: 0px;
    left: 0px;
}
.headingAlt{
  background-color: rgba(223, 223, 223, 0.596);
  width: 100% ;
  height: 80px !important;
  z-index: 2;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.096);
  position: absolute;
  top: 0px;
  left: 0px;
}
.album-header{
  background-color: rgba(223, 223, 223, 0);
  width: 100% ;
  height: 80px !important;
  z-index: 2;
 
  position: absolute;
  top: 0px;
  left: 0px;
}
.header-title{
    text-align: center;
    margin-top: 15px;
    color: rgb(255, 255, 255);
    font-size: 2.6rem;
    font-weight: 200;
    font-family: 'Caveat', cursive;
    text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}



@media only screen and (max-width: 500px) {
    .header-title{
       
       margin: auto;
        color: rgb(255, 255, 255);
      margin-top: 16px;
      padding-left: 0px;
      height: 80%;
      font-size: 2rem;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.144);
    }

    
  }