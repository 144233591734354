.toggleButton{
    height: 24px;
    width: 60px;
    margin-top: 11px;
    margin-right: 0px;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    float: right;
    background: transparent;
    flex-direction: column;
    display: flex;
    position: fixed;
    right: 20px;
    top: 15px;
    z-index: 500 !important;
    border-radius: 100%;
    padding: 3px;
    box-sizing: border-box;
    opacity: 0.9;
}
.toggleButton:hover{
  background-color: none !important;
}
.toggleButton:focus{
    outline: none;
}
.toggle_line{
    width: 35px;
    height: 4px;
    border-radius: 0px;
    background:  #ffffff;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}
.toggle_line2{
    width: 35px;
    height: 3.7px;
    border-radius: 0px;
    background: #ffffff;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}
@media only screen and (max-width: 500px) {
    
    .toggleButton{
        right: 0px;
    }

}