.sideDrawer{
    height: 100vh;
    background-color:  rgb(245, 245, 245);
    box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.205);
    top: 0px;
    right: 0px;
    width: 30% !important;
    position: fixed;
    z-index: 400;
    overflow-y: hidden;
    opacity: .99;
    overflow-x: hidden !important;
    
}
::-webkit-scrollbar {
    display: none;
}
.sideDrawer ul{
    list-style: none;
    width: 27vw;
    height: 900px;
    margin: auto;
    position: fixed;
    padding: 0px;
    padding-bottom: 20px !important;
    margin-top: 60px;
    text-align: center;

}
.sideDrawer li{
    text-align: right;
    font-size: 2.5rem;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    font-family: 'Caveat', cursive;
    align-items: center;
    position: relative;
    border-radius: 5px;
    border-color: transparent;
   
  
}
.sideDrawer li:hover{
 border: none;
   
  
}
.sideDrawer a {
    text-decoration: none;
    color: rgb(122, 122, 122);
}
.sideDrawer a:hover {
    text-decoration: none;
    color: rgba(100, 207, 166, 0.801);
    border: none;
}
.sideDrawer a:active {
    text-decoration: none;
    color: rgb(206, 206, 206);
}
.insta-link{
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    opacity: 0.4;
  }
  .insta-link:hover{
    opacity: 1;
  }
@media only screen and (max-width: 600px) {
    .sideDrawer li{
        text-align: center !important;
        height: 50px;
        margin: auto;
        margin-top: 10px;
        padding-top: 100px;
    }
    .insta-link{
        position: fixed;
        top: 20px;
        left: 20px;
        width: 25px;
        height: 25px;
        opacity: 0.6;
      }
    .sideDrawer{
       width: 100% !important;
        text-align: center !important;
        overflow-y: scroll !important;
    }
    .sideDrawer li p{
      font-size: 2.2rem;
    
      margin: auto;
    
      text-align: center !important;
    }
    .sideDrawer ul{
        text-align: center !important;
        margin-top: 0px !important; 
        margin: auto;
        width: 100vw;
       
    }
    

}