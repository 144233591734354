.portrait-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 80px;
    background-color: rgb(27, 27, 27);
}
.portrait-photo{
    display: flex;
    max-width: 900px;
    width: 30% !important;
    flex-grow: 1;
    flex-shrink: 7;
    align-self: flex-start;
    align-items: stretch;
    margin: 7px;   
}
#last-portrait{
    margin: auto;
  
    padding-bottom: 60px;
    max-width: 98% !important;
}
.sc-bxivhb .dmfLfw{
    background-color: transparent !important;
}
@media only screen and (max-width: 900px) {
    .portrait-photo{
        display: flex;
        max-width: 900px;
        width: 50% !important;
       
        
    }
}
@media only screen and (max-width: 600px) {
    .portrait-photo{
        display: flex;
        
        width: 80% !important;
      
    }
}