@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
.App{
  text-align: center;
  width: 100vw;
  height: auto;
  background-size: cover;
  background-position-y: -600px;
  z-index: 0; 
}
html{
  overflow: hidden !important;
}
html,
body {
    overflow: hidden;
    position: relative;
    height: 100%;
}
.link-wrap{
  height: 400px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  padding-top: 600px;
  margin: auto;
 
}
.albums-link{
  text-decoration: none;
  color: white;
  font-size: 5rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.192);
  font-family: 'Caveat', cursive;
}
.about-link{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.856);
  font-size: 3rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
  font-family: 'Caveat', cursive;
}
.contact-link{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.856);
  font-size: 3rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
  font-family: 'Caveat', cursive;
}
.contact-link:hover{
  color: rgb(185, 58, 158);
}
.about-link:hover{
  color: rgb(55, 177, 161);
}
.albums-link:hover{
  color: rgba(255, 255, 255, 0.418);
}

@media only screen and (max-width: 1400px) {
  .App{
 
    background-position-y: 0px;
  }
}
@media only screen and (max-width: 1200px) {
  .App{
 
    background-position-y: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .App{
  
    background-position-y: 40px ;
  }
}
@media only screen and (max-width: 700px) {
  .App{

    background-position-y: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .link-wrap{
   
  
    flex-direction: column;
   
    width: 100vw;
    height: 100vh;
    justify-content: flex-end;
    padding-left: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .albums-link{
    color: rgba(255, 255, 255, 0.829);
    font-size: 4rem;
    width: 100%;
    height: 40%;
    padding: 0px !important;
    padding-top: 60px !important;
  }
  #home{
    height: 100vh !important;
  }
  .about-link{
    color: rgba(255, 255, 255, 0.829);
    font-size: 3rem;
    width: 100%;
    height: 40%;
    padding: 0px !important;
    padding-top: 120px !important;
   justify-content: center;
  }
  .contact-link{
    font-size: 3rem;
    width: 100%;
    height: 10%;
    padding: 0px !important;
    padding-top: 40px !important;
    padding-bottom: 20px !important;
    border-radius: 20px 20px 0px 0px;
  }
}
@media only screen and (max-width: 300px) {
 
}
