@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
    overflow: hidden;
    position: relative;
    height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  text-align: center;
  width: 100vw;
  height: auto;
  background-size: cover;
  background-position-y: -600px;
  z-index: 0; 
}
html{
  overflow: hidden !important;
}
html,
body {
    overflow: hidden;
    position: relative;
    height: 100%;
}
.link-wrap{
  height: 400px;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 30px;
  padding-top: 600px;
  margin: auto;
 
}
.albums-link{
  text-decoration: none;
  color: white;
  font-size: 5rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.192);
  font-family: 'Caveat', cursive;
}
.about-link{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.856);
  font-size: 3rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
  font-family: 'Caveat', cursive;
}
.contact-link{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.856);
  font-size: 3rem;
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
  font-family: 'Caveat', cursive;
}
.contact-link:hover{
  color: rgb(185, 58, 158);
}
.about-link:hover{
  color: rgb(55, 177, 161);
}
.albums-link:hover{
  color: rgba(255, 255, 255, 0.418);
}

@media only screen and (max-width: 1400px) {
  .App{
 
    background-position-y: 0px;
  }
}
@media only screen and (max-width: 1200px) {
  .App{
 
    background-position-y: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .App{
  
    background-position-y: 40px ;
  }
}
@media only screen and (max-width: 700px) {
  .App{

    background-position-y: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .link-wrap{
   
  
    -webkit-flex-direction: column;
   
  
            flex-direction: column;
   
    width: 100vw;
    height: 100vh;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-left: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .albums-link{
    color: rgba(255, 255, 255, 0.829);
    font-size: 4rem;
    width: 100%;
    height: 40%;
    padding: 0px !important;
    padding-top: 60px !important;
  }
  #home{
    height: 100vh !important;
  }
  .about-link{
    color: rgba(255, 255, 255, 0.829);
    font-size: 3rem;
    width: 100%;
    height: 40%;
    padding: 0px !important;
    padding-top: 120px !important;
   -webkit-justify-content: center;
           justify-content: center;
  }
  .contact-link{
    font-size: 3rem;
    width: 100%;
    height: 10%;
    padding: 0px !important;
    padding-top: 40px !important;
    padding-bottom: 20px !important;
    border-radius: 20px 20px 0px 0px;
  }
}
@media only screen and (max-width: 300px) {
 
}

.header{
    background-color: rgba(204, 204, 204, 0.13);
    width: 100% ;
    height: 80px !important;
    z-index: 2;
    position: fixed;
    top: 0px;
    left: 0px;
}
.headingAlt{
  background-color: rgba(223, 223, 223, 0.596);
  width: 100% ;
  height: 80px !important;
  z-index: 2;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.096);
  position: absolute;
  top: 0px;
  left: 0px;
}
.album-header{
  background-color: rgba(223, 223, 223, 0);
  width: 100% ;
  height: 80px !important;
  z-index: 2;
 
  position: absolute;
  top: 0px;
  left: 0px;
}
.header-title{
    text-align: center;
    margin-top: 15px;
    color: rgb(255, 255, 255);
    font-size: 2.6rem;
    font-weight: 200;
    font-family: 'Caveat', cursive;
    text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}



@media only screen and (max-width: 500px) {
    .header-title{
       
       margin: auto;
        color: rgb(255, 255, 255);
      margin-top: 16px;
      padding-left: 0px;
      height: 80%;
      font-size: 2rem;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.144);
    }

    
  }
.toggleButton{
    height: 24px;
    width: 60px;
    margin-top: 11px;
    margin-right: 0px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border: none;
    cursor: pointer;
    float: right;
    background: transparent;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    right: 20px;
    top: 15px;
    z-index: 500 !important;
    border-radius: 100%;
    padding: 3px;
    box-sizing: border-box;
    opacity: 0.9;
}
.toggleButton:hover{
  background-color: none !important;
}
.toggleButton:focus{
    outline: none;
}
.toggle_line{
    width: 35px;
    height: 4px;
    border-radius: 0px;
    background:  #ffffff;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}
.toggle_line2{
    width: 35px;
    height: 3.7px;
    border-radius: 0px;
    background: #ffffff;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.295);
}
@media only screen and (max-width: 500px) {
    
    .toggleButton{
        right: 0px;
    }

}
.sideDrawer{
    height: 100vh;
    background-color:  rgb(245, 245, 245);
    box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.205);
    top: 0px;
    right: 0px;
    width: 30% !important;
    position: fixed;
    z-index: 400;
    overflow-y: hidden;
    opacity: .99;
    overflow-x: hidden !important;
    
}
::-webkit-scrollbar {
    display: none;
}
.sideDrawer ul{
    list-style: none;
    width: 27vw;
    height: 900px;
    margin: auto;
    position: fixed;
    padding: 0px;
    padding-bottom: 20px !important;
    margin-top: 60px;
    text-align: center;

}
.sideDrawer li{
    text-align: right;
    font-size: 2.5rem;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    font-family: 'Caveat', cursive;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    border-radius: 5px;
    border-color: transparent;
   
  
}
.sideDrawer li:hover{
 border: none;
   
  
}
.sideDrawer a {
    text-decoration: none;
    color: rgb(122, 122, 122);
}
.sideDrawer a:hover {
    text-decoration: none;
    color: rgba(100, 207, 166, 0.801);
    border: none;
}
.sideDrawer a:active {
    text-decoration: none;
    color: rgb(206, 206, 206);
}
.insta-link{
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    opacity: 0.4;
  }
  .insta-link:hover{
    opacity: 1;
  }
@media only screen and (max-width: 600px) {
    .sideDrawer li{
        text-align: center !important;
        height: 50px;
        margin: auto;
        margin-top: 10px;
        padding-top: 100px;
    }
    .insta-link{
        position: fixed;
        top: 20px;
        left: 20px;
        width: 25px;
        height: 25px;
        opacity: 0.6;
      }
    .sideDrawer{
       width: 100% !important;
        text-align: center !important;
        overflow-y: scroll !important;
    }
    .sideDrawer li p{
      font-size: 2.2rem;
    
      margin: auto;
    
      text-align: center !important;
    }
    .sideDrawer ul{
        text-align: center !important;
        margin-top: 0px !important; 
        margin: auto;
        width: 100vw;
       
    }
    

}

.backdrop{
    height: 50px;
    width: 50px;
    z-index: 700;
    border: none;
    padding: 20px;
    border-radius: 200%;
    cursor: pointer;
    background: #31313100;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    right: 35px;
    top: 10px;
    box-sizing: border-box;
}
.backdrop:hover{
    background-color: rgb(73, 73, 73);
}
.moveX{
    margin-top: 2px;
    margin-left: -12px;
}
.click{
    z-index: 600;
}
.toggle_line_x1{
    width: 30px;
    height: 1px;
    margin-bottom: 4px;
    padding: 2px;
    border-radius: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); 
    background:   rgb(55, 177, 161);
}

.toggle_line_x1:hover{
    background-color: white;
   }
.toggle_line_x2:hover{
 background-color: white;
}
.toggle_line_x2{
    width: 30px;
    height: 1px;
    padding: 2px;
    margin-top: -8.5px;
    border-radius: 10px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); 
    background:   rgb(55, 177, 161);
}
@media only screen and (max-width: 600px) {
    .backdrop{
        top: 12px;
        right: 16px;
    }

}
.albums-portraits-background{
    height: 350px;
    width: 80%;
    margin: auto;
    margin-top: 0px;
    background-position-y: -100px;
}
.first-link-wrap{
    padding-top: 80px;
    height: auto;
    background-color: rgb(27, 27, 27);
}
.albums-family-background{
    height: 350px;
    width: 80%;
    margin: auto;
    margin-top: 0px;
    border-radius: 10px;
    background-position-y: -300px;
}
.portraits_link{
   text-decoration: none;

}
#album-page{
    height: auto !important;
}
.portraits-link-text{
    width: 100%;
   bottom: 0px;
   height: 100%;
   overflow:  hidden !important;
   color: rgba(255, 255, 255, 0.692);
    font-size: 10rem;
    font-family: 'Caveat', cursive;
    font-weight: 100;
    margin-top: 10px !important;
    border-radius: 10px 10px 10px 10px !important;
}
.portraits-link-text:hover{
  color: rgba(99, 99, 99, 0.61);
}
.link-text-p{
    margin: 20px;
}
.albums-link-wrap{
   overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .albums-portraits-background{
        height: 400px;
        width: 100% !important;
        border-radius: 10px;
        background-position-y: 0px;
    }
    .albums-family-background{
        height: 400px;
        border-radius: 10px;
        width: 100% !important;
        background-position-y: 0px;
        background-position-x: 0px;
    }
    .portraits-link-text{
    font-size: 6rem;
    height: 75%;
    padding-top: 0px;
    color: rgba(221, 221, 221, 0.795);
    overflow-y: hidden !important;
    border-radius: 10px 10px 0px 0px !important;
    }
}
@media only screen and (max-width: 420px) {
 
    .albums-family-background{
        height: 400px;
        border-radius: 10px;
        width: 100% !important;
        background-position-y: 0px;
        background-position-x: -80px;
    }

}

.contact-form{
    margin: auto;
    margin-top: 0px;   
    width: 60vw;
    height: 70vh;
    padding-top: 80px;
    padding-bottom: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.FormInput{
    outline: none;
    height: 50px;
    width: 50%;
    margin: auto;
    font-family: sans-serif;
    text-indent: 10px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.116) inset;
    border: none !important;
    background-color:  rgba(37, 37, 37, 0.226) !important;
    border-radius: 3px;
}
.overall-section{
    width: 100%;
    height: auto !important;
}
.react-tabs__tab--selected {
    background: rgba(255, 255, 255, 0.836) !important;
    color: rgba(0, 0, 0, 0.418) !important;
    font-family: sans-serif !important;
    bottom: -1px !important;
    border-radius: 5px 5px 0 0;
    border: none !important;
    box-shadow: 0px 8px 10px 3px inset rgba(0, 0, 0, 0.24);
}
.react-tabs__tab {
    display: inline-block;
    border: 0px solid transparent;
    border-bottom: none;
    bottom: 0px;
    color: rgba(0, 0, 0, 0.397) !important;
    padding: 0px !important;
    width: 150px;
    font-weight: 600;
    font-family: 'Caveat', cursive;
    font-size: 1.5rem !important;
    background-color: rgba(255, 255, 255, 0.356);
    border-radius: 5px 5px 0 0;
}
.react-tabs__tab:hover{
   background-color: rgba(209, 209, 209, 0.637);
}
.MessageInput{
    margin-top: 40px !important;
    margin-bottom: 0px !important;
    width: 90% !important;
    height: 200px;
    margin: auto;
    background-color:  rgba(37, 37, 37, 0.226) !important;
    outline: none !important;
    border: none !important;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.192) inset;
    resize: none !important;
    padding-top: 10px;
    border-radius: 3px;
    text-indent: 10px;
}
.FormInput:focus{
    background-color: white !important;
    border-color: transparent !important;
    border-width: 0px;
    font-family: sans-serif;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.062);
}
.MessageInput:focus{
    background-color: white !important;
    border-color: transparent !important;
    border-width: 0px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.062);
}
.Contactbutton{
    width: 90%;
    height: 50px;
    background-color: rgba(53, 150, 145, 0.781);
    margin: auto;
    color: white;
    margin-top: 20px;
    font-size: 1.3rem;
    border: none;
    border-radius: 3px;
}
.Contactbutton:hover{
    background-color: rgba(53, 150, 145, 0.486);
}
.contact-alt-text{
    color: rgba(165, 42, 42, 0.514);
    padding-top: 10px;
    font-size: 1.2rem;
}
.tablist{
    padding-top: 100px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin: auto;
    padding-left: 0px;
}
.contact-wrap{
    height: auto;
}
.tabwrap{
    height: auto;
}
.panel{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.877);
}
.summary-wrap{
    padding-top: 60px;
   width: 80%;
   margin: auto;
   text-align: center;
}
.summary-title{
    font-size: 3rem;
    text-decoration: underline;
    text-underline-offset: 10px;
    font-weight: 100;
    font-family: 'Heebo', sans-serif;
    color: rgba(128, 128, 128, 0.712);
    margin: auto;
    width: 81px;
    margin-right: 40px;
}
.summary-image{
    width: 200px;
    height: 200px;
    margin: auto;
    margin-left: 0px;
    margin-bottom: -15px;
   border-radius: 100%;
}
.summary-top-flex{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: auto;
    padding-bottom: 40px;
    text-align: center;
}

.summary-wrap p{
  font-size: 1.6rem;
  padding-bottom: 70px;
  border: solid rgb(196, 196, 196);
  padding-top: 40px;
  color: rgb(116, 116, 116);
  font-weight: 100;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
.pricing-title{
    font-size: 6rem !important;
    color: rgba(95, 196, 174, 0.623);
    opacity: 0.8;
    font-weight: 100;
    font-family: 'Love Ya Like A Sister', cursive;
}
.pricing-wrap{
    padding-top: 40px;
    background-color: rgba(128, 128, 128, 0.493);
    height: auto;
    box-shadow: 0px 10px 12px 0px inset rgba(0, 0, 0, 0.534);
}
.dmfLfw{
    height: auto !important;
    overflow-y: visible;
}
.price-list{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 60%;
    margin: auto;
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 3rem;
    padding-bottom: 40px;
    font-weight: 100 !important;
    font-family: 'Love Ya Like A Sister', cursive;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.price-list-item-title{
    font-weight: 100 !important;
    font-size: 3.4rem !important;
}
.contact-email{
    font-size: 1.4em;
    font-family: sans-serif;
    color: gray;
}
.price-text{
    color: black;
}
.price-text-span{
    padding-left: 50px;
}
.price-list-item{
list-style: none;
}
.contact-insta-link{
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 0px;
}
@media only screen and (max-width: 600px) {
    .contact-form{
     
        width: 90vw;
        height: 70vh;
    }
    .react-tabs__tab--selected{
       bottom: -1px !important;
    }
    .react-tabs__tab{
       width: 40%;
      font-size: 1.2rem !important;
      bottom: -1px !important;
    }
    .FormInput{
        margin: auto !important;
        width: 90%;
    }
    .contact-email{
        font-size: .8em;
     
    }
    .price-list{
        width: 90%;
        padding: 0px;
        color: rgba(255, 255, 255, 0.658);
    }
    .price-list-item-title{
        font-size: 3rem !important;
        color: white;
    }
    .summary-image{
        width: 150px;
        height: 175px;
        margin-top: 30px;
    }
    .price-list{
        font-size: 2.4rem;
        overflow-x: hidden;
    }
}
html{
    width: 100%;

    overflow-y: scroll !important;
}
body{
    width: 100% !important;
    overflow-x: hidden;
    overflow-y: scroll !important;
    height: auto !important;
}
.smooth{
    height: 100vh !important;
}

.Pages{
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.about-wrap{
    padding-top: 100px;
    width: 100%;
    overflow-y: scroll;
}
.about-topgroup-wrap{
     display: -webkit-flex;
     display: flex;
    padding-top: 50px;
      margin: auto;
     width: 800px;
}
.awssld__wrapper{
    overflow-y: scroll;
}
.awssld__bullets button{
    background-color: rgb(223, 223, 223) !important;
}
.awssld__bullets .awssld__bullets--active{
    background-color:  rgba(55, 177, 161, 0.603) !important;
}
.about-photo{
    padding-top: 100px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 40px;
    box-shadow: 8px 5px 6px rgba(0, 0, 0, 0.178);
    width: 300px !important;
}
#aboutTitle{
    text-align: right;
}

.awssld__content{
    overflow-x: hidden;
    width: 100% !important;
    background-color: rgb(245, 245, 245) !important;
  
}
.slideImages{
    height: auto !important;
    width: 100% !important;
   
}
.slide-show-wrap{
    padding-bottom: 100px;
}
.about-title1{
    color: silver;
    padding-right: 18px;
    font-size: 2rem;
    font-weight: 100;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    margin-bottom: -8px;
    letter-spacing: 7px;
    }
    .about-title2{
    color: gray;
    font-family: 'Roboto';
    padding-right: 20px;
    font-size: 2.5rem;
    margin-top: 0px;
    letter-spacing: 7px;
    }
    .slide-link{
        font-size: 1.3rem;
        text-decoration: none;
       width: 250px;
       padding: 100px;
       height: 150px;
       border-radius: 200px;
       background-color: rgb(230, 230, 230);
        font-family: sans-serif;
        color: rgb(139, 139, 139);
    }
.about-title1{
color: silver;
padding-right: 18px;
font-size: 2rem;
font-weight: 100;
font-style: italic;
font-family: 'Roboto', sans-serif;
margin-bottom: -8px;
letter-spacing: 7px;
}
.about-title2{
color: gray;
font-family: 'Roboto';
padding-right: 20px;
font-size: 2.5rem;
margin-top: 0px;
letter-spacing: 7px;
}
.about-text-wrap-1{
    width: 100%;
    font-size: 1.6rem;
    padding: 30px;
    overflow-y: scroll !important;
    margin: auto;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(126, 126, 126);
}
.about-text-wrap-2{
    width: 800px;
    margin: auto;
    margin-top: -50px;
    font-size: 1.6rem;
    overflow-y: scroll !important;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(126, 126, 126);
    padding: 30px;
    padding-bottom: 100px;
  
}
.sc-bwzfXH {
 width: 100%
 
}
.fhNIzM{
   height: auto !important;
    width: 400px;
}
@media only screen and (max-width: 1400px) {

  }
  @media only screen and (max-width: 900px) {
    .about-topgroup-wrap{
        display: -webkit-flex;
        display: flex;
        padding-top: 50px;
          margin: auto;
          -webkit-flex-direction: column;
                  flex-direction: column;
         width: 90%;
         
    }
    .about-wrap{
        padding-top: 100px;
        width: 100%;
    }
    .about-text-wrap-1{
        width: 85%;
        text-align: left;
        display: -webkit-flex;
        display: flex;
        height: auto;
        font-size: 1.4rem;
        padding: 10px;
    }
  
    .about-text-wrap-2{
        width: 80%;
        margin: auto;
        padding-bottom: 60px;
        text-align: left;
 
        font-size: 1.4rem;

    }
    .sc-bwzfXH {
        width: auto;
        min-width: none;
        max-height: 700px;
       }
       .about-photo{
        width: 200px !important;
        padding: 10px;
        display: -webkit-flex;
        display: flex;
        margin: auto;
        width: 100%;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.411) !important;
        min-width: none !important;
    }
       .fhNIzM{
           width: auto;
           display: -webkit-flex;
           display: flex;
           -webkit-flex-wrap: wrap;
                   flex-wrap: wrap;
           
       }

  }
  @media only screen and (max-width: 600px) {
    #aboutTitle{
        text-align: center;
        padding-left: 20px;
    }
    .about-photo{
        width: 85% !important;
        padding: 10px;

        min-width: none !important;
    }
    .slideImages{
        height: auto;
        width: 100%;
        padding: 0px;
        
        box-shadow: none;
    }
  }
  @media only screen and (max-width: 400px) {
    .about-text-wrap-1{
      
        font-size: 1.3rem;
    }
    .about-text-wrap-2{
        margin-top: -40px;
        font-size: 1.3rem;
    }
  
    .about-topgroup-wrap{
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
}
.portrait-wrap{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 80px;
    background-color: rgb(27, 27, 27);
}
.portrait-photo{
    display: -webkit-flex;
    display: flex;
    max-width: 900px;
    width: 30% !important;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 7;
            flex-shrink: 7;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;
    margin: 7px;   
}
#last-portrait{
    margin: auto;
  
    padding-bottom: 60px;
    max-width: 98% !important;
}
.sc-bxivhb .dmfLfw{
    background-color: transparent !important;
}
@media only screen and (max-width: 900px) {
    .portrait-photo{
        display: -webkit-flex;
        display: flex;
        max-width: 900px;
        width: 50% !important;
       
        
    }
}
@media only screen and (max-width: 600px) {
    .portrait-photo{
        display: -webkit-flex;
        display: flex;
        
        width: 80% !important;
      
    }
}
