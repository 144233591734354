@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100&display=swap');
.contact-form{
    margin: auto;
    margin-top: 0px;   
    width: 60vw;
    height: 70vh;
    padding-top: 80px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
}
.FormInput{
    outline: none;
    height: 50px;
    width: 50%;
    margin: auto;
    font-family: sans-serif;
    text-indent: 10px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.116) inset;
    border: none !important;
    background-color:  rgba(37, 37, 37, 0.226) !important;
    border-radius: 3px;
}
.overall-section{
    width: 100%;
    height: auto !important;
}
.react-tabs__tab--selected {
    background: rgba(255, 255, 255, 0.836) !important;
    color: rgba(0, 0, 0, 0.418) !important;
    font-family: sans-serif !important;
    bottom: -1px !important;
    border-radius: 5px 5px 0 0;
    border: none !important;
    box-shadow: 0px 8px 10px 3px inset rgba(0, 0, 0, 0.24);
}
.react-tabs__tab {
    display: inline-block;
    border: 0px solid transparent;
    border-bottom: none;
    bottom: 0px;
    color: rgba(0, 0, 0, 0.397) !important;
    padding: 0px !important;
    width: 150px;
    font-weight: 600;
    font-family: 'Caveat', cursive;
    font-size: 1.5rem !important;
    background-color: rgba(255, 255, 255, 0.356);
    border-radius: 5px 5px 0 0;
}
.react-tabs__tab:hover{
   background-color: rgba(209, 209, 209, 0.637);
}
.MessageInput{
    margin-top: 40px !important;
    margin-bottom: 0px !important;
    width: 90% !important;
    height: 200px;
    margin: auto;
    background-color:  rgba(37, 37, 37, 0.226) !important;
    outline: none !important;
    border: none !important;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.192) inset;
    resize: none !important;
    padding-top: 10px;
    border-radius: 3px;
    text-indent: 10px;
}
.FormInput:focus{
    background-color: white !important;
    border-color: transparent !important;
    border-width: 0px;
    font-family: sans-serif;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.062);
}
.MessageInput:focus{
    background-color: white !important;
    border-color: transparent !important;
    border-width: 0px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.062);
}
.Contactbutton{
    width: 90%;
    height: 50px;
    background-color: rgba(53, 150, 145, 0.781);
    margin: auto;
    color: white;
    margin-top: 20px;
    font-size: 1.3rem;
    border: none;
    border-radius: 3px;
}
.Contactbutton:hover{
    background-color: rgba(53, 150, 145, 0.486);
}
.contact-alt-text{
    color: rgba(165, 42, 42, 0.514);
    padding-top: 10px;
    font-size: 1.2rem;
}
.tablist{
    padding-top: 100px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin: auto;
    padding-left: 0px;
}
.contact-wrap{
    height: auto;
}
.tabwrap{
    height: auto;
}
.panel{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.877);
}
.summary-wrap{
    padding-top: 60px;
   width: 80%;
   margin: auto;
   text-align: center;
}
.summary-title{
    font-size: 3rem;
    text-decoration: underline;
    text-underline-offset: 10px;
    font-weight: 100;
    font-family: 'Heebo', sans-serif;
    color: rgba(128, 128, 128, 0.712);
    margin: auto;
    width: 81px;
    margin-right: 40px;
}
.summary-image{
    width: 200px;
    height: 200px;
    margin: auto;
    margin-left: 0px;
    margin-bottom: -15px;
   border-radius: 100%;
}
.summary-top-flex{
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-bottom: 40px;
    text-align: center;
}

.summary-wrap p{
  font-size: 1.6rem;
  padding-bottom: 70px;
  border: solid rgb(196, 196, 196);
  padding-top: 40px;
  color: rgb(116, 116, 116);
  font-weight: 100;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
.pricing-title{
    font-size: 6rem !important;
    color: rgba(95, 196, 174, 0.623);
    opacity: 0.8;
    font-weight: 100;
    font-family: 'Love Ya Like A Sister', cursive;
}
.pricing-wrap{
    padding-top: 40px;
    background-color: rgba(128, 128, 128, 0.493);
    height: auto;
    box-shadow: 0px 10px 12px 0px inset rgba(0, 0, 0, 0.534);
}
.dmfLfw{
    height: auto !important;
    overflow-y: visible;
}
.price-list{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 3rem;
    padding-bottom: 40px;
    font-weight: 100 !important;
    font-family: 'Love Ya Like A Sister', cursive;
    justify-content: space-between;
}
.price-list-item-title{
    font-weight: 100 !important;
    font-size: 3.4rem !important;
}
.contact-email{
    font-size: 1.4em;
    font-family: sans-serif;
    color: gray;
}
.price-text{
    color: black;
}
.price-text-span{
    padding-left: 50px;
}
.price-list-item{
list-style: none;
}
.contact-insta-link{
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 0px;
}
@media only screen and (max-width: 600px) {
    .contact-form{
     
        width: 90vw;
        height: 70vh;
    }
    .react-tabs__tab--selected{
       bottom: -1px !important;
    }
    .react-tabs__tab{
       width: 40%;
      font-size: 1.2rem !important;
      bottom: -1px !important;
    }
    .FormInput{
        margin: auto !important;
        width: 90%;
    }
    .contact-email{
        font-size: .8em;
     
    }
    .price-list{
        width: 90%;
        padding: 0px;
        color: rgba(255, 255, 255, 0.658);
    }
    .price-list-item-title{
        font-size: 3rem !important;
        color: white;
    }
    .summary-image{
        width: 150px;
        height: 175px;
        margin-top: 30px;
    }
    .price-list{
        font-size: 2.4rem;
        overflow-x: hidden;
    }
}